import React from "react"
import { useAsync } from "react-async"
import Spinner from "@atlaskit/spinner"
import { apis } from "./shared/apis"

const Version = ({ commit }) => {
    if (!commit) return null
    return <span style={{ color: "#fff" }}>(v. {commit})</span>
}

export const useVersion = () => {
    return useAsync({
        promiseFn: apis.version.get,
    })
}

const VersionFetch = () => {
    const { data, error, isLoading } = useVersion()

    if (isLoading) return <Spinner size="xsmall" isCompleting={false} />
    if (error) return "(working copy)"
    if (data) return <Version {...data} />
    return null
}

export default VersionFetch
