import React from "react"
import ModalDialog from "@atlaskit/modal-dialog"
import Form from "../../views/shared/components/form"
import TextField from "../../views/shared/components/textfield"
import useFormist from "react-formist"
import { useSecurity } from "./security-context"
import { mkSchema } from "./helpers"

export default function ChangePasswordModal({
    isPasswordExpired,
    onCancel,
    onConfirm,
    onError,
}) {
    const [user, api] = useSecurity()
    const initialData = {
        currentPassword: "",
        nextPassword: "",
    }
    const title = isPasswordExpired
        ? `Expired password for: ${user.email}`
        : `Change password for: ${user.email}`

    const formist = useFormist(initialData, {
        schema: mkSchema(user),
        onSubmit: (formData) => {
            api.changePassword(
                user.email,
                formData.currentPassword,
                formData.nextPassword,
            )
                .then((result) => {
                    const isOk = result && !result.error && !result.ajaxError
                    const err = result.error || result.ajaxError

                    if (isOk) onConfirm(result)
                    else onError(err)
                })
                .then(api.logout)
        },
    })

    return (
        <ModalDialog heading={title}>
            <Form
                {...formist.getFormProps()}
                size="full"
                primaryText="Change"
                onCancel={onCancel}>
                <div className="row">
                    <TextField
                        type="password"
                        label="Current password"
                        width="col"
                        {...formist.getFieldProps("currentPassword")}
                    />
                </div>
                <div className="row">
                    <TextField
                        type="password"
                        label="Next password"
                        width="col"
                        {...formist.getFieldProps("nextPassword")}
                    />
                </div>
            </Form>
        </ModalDialog>
    )
}
