import React from "react"
import { withRouter } from "react-router-dom"
import MenuSection from "./menu-section"

function parseResource(value) {
    const parts = value.split("/")
    return parts[1]
}

export function matchesLocation(item, location) {
    const itemResource = parseResource(item.url)
    const locationResource = parseResource(location.pathname)
    return itemResource === locationResource
}

const Menu = ({ items, location }) => {
    const isSelected = (item, useDefault) => {
        return matchesLocation(item, location) || (useDefault && item.isDefault)
    }

    return (
        <>
            {items.map((section) => {
                return (
                    <MenuSection
                        key={section.title}
                        section={section}
                        isSelected={isSelected}
                        matchesLocation={(item) =>
                            matchesLocation(item, location)
                        }
                    />
                )
            })}
        </>
    )
}
export default withRouter(Menu)
