import React from "react"

const MenuSectionTitle = ({ title }) => {
    return (
        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>{title}</span>
            <a className="d-flex align-items-center text-muted" href="#">
                <span data-feather="plus-circle" />
            </a>
        </h6>
    )
}

export default MenuSectionTitle
