import React from "react"
import Menu from "../layout/menu"
import { menuItems } from "../../../shared/routing"
import "./sidebar.scss"

export default function Sidebar({ user }) {
    return (
        <nav className="d-none d-md-block bg-light sidebar">
            <div className="sidebar-sticky">
                {(user.token && <Menu items={menuItems} />) || null}
            </div>
        </nav>
    )
}
