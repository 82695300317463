import React, { Component } from "react"
import * as Sentry from "@sentry/browser"

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { eventId: null }
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo)
            const eventId = Sentry.captureException(error)
            this.setState({ eventId })
        })
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <h3>
                        C&apos;&egrave; stato un errore.{" "}
                        <a href="/">Ricarica l&apos;applicazione.</a>
                    </h3>
                    {this.props.monitoringEnabled ? (
                        <>
                            <h4>
                                Per evitare che l&apos;errore si ripeta in
                                futuro, invia un messaggio a doubleloop,
                                descrivendo ci&ograve; che stavi facendo, con il
                                pulsante seguente.
                            </h4>

                            <button
                                onClick={() =>
                                    Sentry.showReportDialog({
                                        title: "Descrivi il problema",
                                        subtitle:
                                            "includendo più dettagli possible (es. codice utenza o condominio)",
                                        subtitle2: "",
                                        eventId: this.state.eventId,
                                    })
                                }>
                                Invia informazioni aggiuntive
                            </button>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary
