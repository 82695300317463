import { error } from "../notifications"
import * as yup from "yup"

export const showError = (e) => {
    let ex = e
    if (typeof e === "string") {
        try {
            ex = JSON.parse(e)
        } catch (e) {
            ex = {
                Message: "Error in change password",
                StackTrace: "",
            }
        }
    }
    if (ex && ex.Message) {
        error(ex.Message)
        console.error(ex.StackTrace)
    }
}

const passwordErrors = {
    separator: "; ",
    serialize: (errors) => errors.join(passwordErrors.separator),
    deserialize: (message) => message.split(passwordErrors.separator),
}

const passwordConfig = {
    minimumLength: 16,
    symbols: "!@#$%^&*",
}

export const goodPasswordCheck =
    (minimumLength, symbols) => (userEmail, password) =>
        run(minimumLength, symbols, userEmail, password)

const run = (minimumLength, symbols, userEmail, password) => {
    const chars = Array.from(password)

    const validations = [
        verifyMinimumLength(minimumLength, password),
        verifyAtLeastOneSymbol(symbols, chars),
        verifyAtLeastOneDigit(chars),
        verifyAtLeastOneUppercaseLetter(chars),
        verifyDoNotContainsForbidden(password, [
            extractUserName(userEmail),
            "next",
            "genomics",
            "personal",
            "sbilanciati",
            "doubleloop",
        ]),
    ]

    const errors = validations.filter((x) => x.type === "ERROR")
    if (errors.length)
        return { type: "ERRORS", errors: errors.map((x) => x.value) }

    return success()
}

const verifyMinimumLength = (minimumLength, password) =>
    password.length < minimumLength
        ? notVerified("NOT_LONG_ENOUGH")
        : verified()

const verifyAtLeastOneSymbol = (symbols, password) =>
    atLeastOneFor((x) => symbols.includes(x), "MISSING_SYMBOL")(password)

const verifyAtLeastOneDigit = (password) =>
    atLeastOneFor(isDigit, "MISSING_DIGIT")(password)

const verifyAtLeastOneUppercaseLetter = (password) =>
    atLeastOneFor(isUppercaseLetter, "MISSING_UPPERCASE_LETTER")(password)

const verifyDoNotContainsForbidden = (password, words) =>
    words.some((w) => password.includes(w))
        ? notVerified("FORBIDDEN_WORDS")
        : verified()

const atLeastOneFor = (predicate, error) => (password) =>
    !password.some(predicate) ? notVerified(error) : verified()

const extractUserName = (userEmail) => {
    const [userName] = userEmail.split("@")
    return userName
}

export const success = () => ({ type: "SUCCESS" })
const verified = () => ({ type: "VERIFIED" })
const notVerified = (value) => ({ type: "ERROR", value })

const isDigit = (s) => /\d/.test(s)
const isUppercaseLetter = (s) => /[A-Z]/.test(s)

const mkPasswordSchema = (minimumLength, symbols, userEmail) =>
    yup
        .string()
        .test({
            name: "verify",
            params: { minimumLength, symbols },
            test: function verify(value) {
                if (!value)
                    return this.createError({
                        message: "nextPassword is a required field",
                    })
                const result = goodPasswordCheck(minimumLength, symbols)(
                    userEmail,
                    value,
                )
                if (result.type === "SUCCESS") return true
                const message =
                    "nextPassword is invalid: " +
                    passwordErrors.serialize(
                        result.errors.map((x) => translateErrorMessage[x]),
                    )
                return this.createError({ message })
            },
        })
        .required()

const translateErrorMessage = {
    NOT_LONG_ENOUGH: "minimum required length is ${minimumLength} chars",
    MISSING_SYMBOL: "missing symbol, must include one of ${symbols}",
    MISSING_DIGIT: "missing digit",
    MISSING_UPPERCASE_LETTER: "missing uppercase letter",
    FORBIDDEN_WORDS: "contains user name/email",
}

export const mkSchema = (user) =>
    yup.object().shape({
        currentPassword: yup.string().required(),
        nextPassword: mkPasswordSchema(
            passwordConfig.minimumLength,
            passwordConfig.symbols,
            user.email,
        ).test({
            name: "differentPasswords",
            message:
                "nextPassword is invalid: cannot use same password as the current one",
            test: function test(value) {
                if (!value)
                    return this.createError({
                        message: "nextPassword is a required field",
                    })
                return value !== this.parent["currentPassword"]
            },
        }),
    })
