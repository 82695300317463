import React from "react"
import "./navbar.scss"

export default function Navbar({ children }) {
    return (
        <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
            <a className="navbar-brand col-sm-3 col-md-2 mr-0" href="#">
                Next Genomics
            </a>
            {React.Children.map(children, (c) => c)}
        </nav>
    )
}
