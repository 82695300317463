import React from "react"
import SecuredRoute from "../components/secured-route"
import { useSecurity } from "../../../shared/security/security-context"
import Login from "../components/login"
import { map, filter, is, compose, values, flatten } from "ramda"
import { useAsync } from "react-async"
import Spinner from "@atlaskit/spinner"
import ErrorBoundary from "../components/error-boundary"
import { Switch } from "react-router-dom"

function extractObjWithUrlProp(obj) {
    const justObjects = compose(filter(is(Object)), values)
    const recurse = map(extractObjWithUrlProp)

    if (obj.url) return obj
    return compose(recurse, justObjects)(obj)
}

const Routes = ({ routes, user, securityApi, monitoringEnabled }) => {
    const allRoutes = flatten(extractObjWithUrlProp(routes))
    return (
        <ErrorBoundary monitoringEnabled={monitoringEnabled}>
            <Switch>
                {allRoutes.map((route) => {
                    return (
                        <SecuredRoute
                            key={route.url}
                            exact={!route.routes}
                            path={route.url}
                            loggedIn={user && !!user.token}
                            loginComponent={() => (
                                <Login onLogin={securityApi.login} />
                            )}
                            component={route.component}
                        />
                    )
                })}
            </Switch>
        </ErrorBoundary>
    )
}

const loadData = ({ api, user }) => {
    return api.validate(user)
}

const RoutesFetch = ({ routes, monitoringEnabled }) => {
    const [user, api, storedUser] = useSecurity()
    const { error, isLoading } = useAsync({
        promiseFn: loadData,
        api,
        user: storedUser,
    })

    if (isLoading) return <Spinner size="xlarge" isCompleting={false} />
    if (error) return `Something went wrong: ${error.message}`
    return (
        <Routes
            routes={routes}
            user={user}
            securityApi={api}
            monitoringEnabled={monitoringEnabled}
        />
    )
}

export default RoutesFetch
