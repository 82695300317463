import React from "react"
import ModalDialog from "@atlaskit/modal-dialog"
import * as yup from "yup"
import useFormist from "react-formist"
import Form from "./form"
import TextField from "./textfield"
import { useSecurity } from "../../../shared/security/security-context"

const initialValues = {
    email: "",
    password: "",
}

const schema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required(),
})

const Login = ({ onLogin, model = initialValues }) => {
    const [user] = useSecurity()
    const loginError = user && user.error
    const formist = useFormist(model, {
        schema,
        onSubmit: (data) => {
            onLogin(data.email, data.password)
        },
    })
    return (
        <ModalDialog id="login-dialog" heading="Login">
            <Form
                title=""
                size="full"
                buttons={["submit"]}
                primaryText="Login"
                {...formist.getFormProps()}>
                <div className="form-row">
                    <TextField
                        label="Email"
                        width="col"
                        {...formist.getFieldProps("email")}
                    />
                </div>
                <div className="form-row">
                    <TextField
                        label="Password"
                        type="password"
                        width="col"
                        {...formist.getFieldProps("password")}
                    />
                </div>
            </Form>
            {loginError && <div className="login-error">{loginError}</div>}
            <br />
        </ModalDialog>
    )
}

export default Login
