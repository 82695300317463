import React from "react"
import MenuSectionTitle from "./menu-section-title"
import MenuItem from "./menu-item"

const MenuSection = ({ section, isSelected, matchesLocation }) => {
    const useDefault =
        section.items.filter((item) => matchesLocation(item)).length === 0
    return (
        <>
            <MenuSectionTitle title={section.title} />
            <ul className="nav flex-column ">
                {section.items.map((item) => {
                    return (
                        <MenuItem
                            key={item.title}
                            title={item.title}
                            url={item.url}
                            selected={isSelected(item, useDefault)}
                        />
                    )
                })}
            </ul>
        </>
    )
}

export default MenuSection
