import { useState, useEffect } from "react"
import { useSecurity } from "../../../shared/security/security-context"
import { useVersion } from "../../../version"
import * as Sentry from "@sentry/browser"

const checkSentry = (setMonitoring) => {
    if (Sentry.getCurrentHub().getClient()) {
        setMonitoring(true)
    } else {
        setTimeout(() => checkSentry(setMonitoring), 1000)
    }
}

export const useMonitoring = () => {
    const { data } = useVersion()
    const [user] = useSecurity()
    const [monitoring, setMonitoring] = useState(false)
    useEffect(() => {
        if (
            data &&
            data.commit &&
            user &&
            user.token &&
            !Sentry.getCurrentHub().getClient()
        ) {
            Sentry.init({
                dsn: global.__sentry_dsn__,
                release: parseInt(data.commit.split("-")[0], 10),
            })
            Sentry.configureScope(function (scope) {
                scope.setUser({ email: user.email })
                scope.setTag("source", "client")
            })
        }
    }, [data, user])
    useEffect(() => {
        checkSentry(setMonitoring)
    }, [])
    return [monitoring]
}
