import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import DropdownMenu, {
    DropdownItem,
    DropdownItemGroup,
} from "@atlaskit/dropdown-menu"
import { useSecurity } from "./security-context"
import ChangePasswordModal from "./change-password-modal"
import { success } from "../notifications"
import { showError } from "./helpers"

const User = () => {
    const [user, api] = useSecurity()
    const [showChangePassword, setShowChangePassword] = useState(false)
    const isPasswordExpired = user.isPasswordExpired

    const onCancelChangePassword = (e) => {
        e.preventDefault()
        setShowChangePassword(false)
    }

    const onClickChangePassword = (e) => {
        e.preventDefault()
        setShowChangePassword(true)
    }

    const onConfirmChangePassword = () => {
        success("Password changed. You'll be signed out.")
        setShowChangePassword(false)
    }

    const onErrorChangePassword = (err) => {
        showError(err)
    }

    useEffect(() => {
        if (isPasswordExpired) {
            setShowChangePassword(true)
        }
    }, [isPasswordExpired])

    return user ? (
        <>
            <span className="nav-link">
                <DropdownMenu trigger={user.email} triggerType="button">
                    <DropdownItemGroup>
                        <DropdownItem>
                            <Link to="" onClick={onClickChangePassword}>
                                Change password
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="" onClick={api.logout}>
                                Sign out
                            </Link>
                        </DropdownItem>
                    </DropdownItemGroup>
                </DropdownMenu>
            </span>
            {showChangePassword && (
                <ChangePasswordModal
                    isPasswordExpired={isPasswordExpired}
                    onCancel={onCancelChangePassword}
                    onConfirm={onConfirmChangePassword}
                    onError={onErrorChangePassword}
                />
            )}
        </>
    ) : null
}

export default User
