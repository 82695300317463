import React from "react"
import { Route } from "react-router-dom"

const SecuredRoute = ({ exact, path, component, loggedIn, loginComponent }) => {
    return (
        (loggedIn && (
            <Route exact={exact} path={path} component={component} />
        )) || <Route exact={exact} path={path} component={loginComponent} />
    )
}

export default SecuredRoute
