import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"

const MenuItem = ({ title, url, selected, onClick }) => {
    const cssClasses = classNames("nav-link", { active: selected })
    return (
        <li className="nav-item">
            <Link className={cssClasses} to={url} onClick={onClick}>
                {title}
            </Link>
        </li>
    )
}

export default MenuItem
