import "./index.scss"

import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import Routes from "../layout/routes"
import { routes } from "../../../shared/routing"
import ReactNotification from "react-notifications-component"
import "react-notifications-component/dist/theme.css"
import { useSecurity } from "../../../shared/security/security-context"
import { useMonitoring } from "../components/monitoring"
import Navbar from "./navbar"
import Sidebar from "./sidebar"

export default function Layout({ children }) {
    const [user] = useSecurity()
    const [monitoring] = useMonitoring()

    return (
        <Router>
            <ReactNotification />
            <Navbar>{children}</Navbar>
            <Sidebar user={user} />
            <main role="main" className="px-4">
                <Routes routes={routes} monitoringEnabled={monitoring} />
            </main>
        </Router>
    )
}
